import { render, staticRenderFns } from "./thesaurus_management.vue?vue&type=template&id=6c054f6d&scoped=true&"
import script from "./thesaurus_management.vue?vue&type=script&lang=js&"
export * from "./thesaurus_management.vue?vue&type=script&lang=js&"
import style0 from "./thesaurus_management.vue?vue&type=style&index=0&id=6c054f6d&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c054f6d",
  null
  
)

export default component.exports