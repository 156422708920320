<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>产品管理</el-breadcrumb-item>
      <el-breadcrumb-item>字库订单管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <!-- 筛选 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col>
          <span class="title_class">字库信息筛选</span>
        </el-col>
      </el-row>
      <el-form
        :model="queryForm"
        class="filter_Form"
        label-width="100px"
        ref="queryFormRef"
      >
        <el-row :gutter="50">
          <el-col :span="6">
            <el-form-item label="赛季" prop="season">
              <el-select
                placeholder="请选择赛季"
                style="width: 100%"
                v-model="queryForm.season"
              >
                <el-option
                  v-for="item in dict_season"
                  :key="item.dictValue"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="选手姓名" prop="userName">
              <el-input
                v-model="queryForm.userName"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="手机号" prop="phone">
              <el-input
                v-model="queryForm.phone"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="开通类型" prop="openType">
              <el-select
                placeholder="请选择赛季"
                style="width: 100%"
                v-model="queryForm.openType"
              >
                <el-option
                  v-for="item in p_open_type"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row :gutter="20" type="flex" justify="end">
        <el-col :span="2">
          <el-button
            type="primary"
            style="width: 100%"
            @click="getDataList('search')"
            >查询</el-button
          >
        </el-col>
        <el-col :span="2">
          <el-button type="primary" plain style="width: 100%" @click="reset"
            >重置</el-button
          >
        </el-col>
      </el-row>
    </el-card>
    <!-- 学校列表 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row" align="middle" type="flex">
        <el-col :span="9">
          <span class="title_class">选手开通信息</span
          >
        </el-col>
        <el-col :span="15">
          <div class="div_algin_right">
            <el-button type="primary" @click="editInfoDialogVisible = true"
              >开通字库</el-button
            >
          </div>
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="dataList"
        border
        stripe
      >
        <el-table-column label="序号" type="index"></el-table-column>
        <el-table-column label="开通用户" prop="userName"></el-table-column>
        <el-table-column
          label="组别"
          prop="openLevel"
          :formatter="groupFormatter"
        ></el-table-column>
        <el-table-column label="联系方式" prop="phone"></el-table-column>
        <el-table-column
          label="产品名称"
          prop="productType"
          :formatter="productTypeFormatter"
        ></el-table-column>
        <el-table-column
          label="开通类型"
          prop="openType"
          :formatter="openTypeFormatter"
          width="80px"
        ></el-table-column>
        <el-table-column label="开始时间" prop="beginTime"></el-table-column>
        <el-table-column label="结束时间" prop="endTime"></el-table-column>
        <el-table-column label="创建时间" prop="createTime"></el-table-column>
        <el-table-column label="订单号" prop="orderNo"></el-table-column>
        <el-table-column label="备注" prop="remark"></el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.pageNum"
        :page-sizes="[10, 20, 40, 80]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 编辑信息的dialog -->
    <el-dialog
      title="添加开通信息"
      :visible.sync="editInfoDialogVisible"
      width="60%"
      @close="editInfoDialogClose"
    >
      <el-form
        :model="userInfoForm"
        label-width="100px"
        ref="userInfoFormRef"
        :rules="userInfoRules"
        style="width: 100%"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="手机号" prop="phone">
              <el-input
                v-model="userInfoForm.phone"
                placeholder="请输入开通账号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="showEditInfoForm">
            <el-form-item label="用户名">
              <el-input
                v-model="userInfoData.userName"
                placeholder="请输入"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="showEditInfoForm">
          <el-col :span="12">
            <el-form-item label="学校" prop="schoolName">
              <el-input
                v-model="userInfoData.schoolName"
                placeholder="请输入"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="组别" prop="level">
              <el-select
                placeholder="请选择"
                v-model="userInfoData.level"
                style="width: 100%"
                disabled
              >
                <el-option
                  v-for="item in dict_group"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer" v-if="!showEditInfoForm">
        <el-button type="primary" @click="getUserInfoDetail">查询</el-button>
      </span>
      <el-form
        :model="editInfoForm"
        label-width="100px"
        ref="editInfoFormRef"
        :rules="editInfoRules"
        style="width: 100%"
        v-if="showEditInfoForm"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="产品类型" prop="productType">
              <el-select
                placeholder="请选择"
                v-model="editInfoForm.productType"
                style="width: 100%"
                @change="productTyChange"
              >
                <el-option
                  v-for="item in p_word_product_type"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="开通类型" prop="openType">
              <el-select
                placeholder="请选择"
                v-model="editInfoForm.openType"
                style="width: 100%"
              >
                <el-option
                  v-for="item in p_open_type"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="开始时间" prop="beginTime">
              <el-date-picker
                type="date"
                placeholder="选择日期/时间"
                value-format="yyyy-MM-dd HH:mm:ss"
                style="width: 100%"
                v-model="editInfoForm.beginTime"
                @change="addBeginTimeChangeStart"
                clearable
                :picker-options="addTimepickerOptionsStart"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="结束时间" prop="endTime">
              <el-date-picker
                type="date"
                placeholder="选择日期/时间"
                value-format="yyyy-MM-dd HH:mm:ss"
                style="width: 100%"
                clearable
                v-model="editInfoForm.endTime"
                @change="addEndTimeChangeEnd"
                :picker-options="addTimepickerOptionsEnd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="赛季" prop="season">
              <el-select
                placeholder="请选择"
                v-model="editInfoForm.season"
                style="width: 100%"
              >
                <el-option
                  v-for="item in dict_season"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remark">
              <el-input
                v-model="editInfoForm.remark"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <span v-if="isExpired === 1" style="color: red"
          >注*该用户已开通该产品，此时显示的开始时间为该产品的结束时间。</span
        >
      </el-form>
      <span slot="footer" class="dialog-footer" v-if="showEditInfoForm">
        <el-button @click="editInfoDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editInfo">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { queryOpenProductList, getSysUserInfo, addOpenProductList, queryOpenProductById } from '@/http/api'
export default {
  data () {
    return {
      isExpired: 0,
      editInfoDialogVisible: false,
      showEditInfoForm: false,
      dataList: [],
      total: 0,
      userInfoData: {},
      userInfoForm: {
        phone: ''
      },
      queryForm: {
        activityType: this.$chnEngStatusCode.activityType,
        season: this.$chnEngStatusCode.defaultSeason,
        pageNum: 1,
        pageSize: 10,
        openType: '',
        phone: '',
        userName: ''
      },
      editInfoForm: {
        activityType: this.$chnEngStatusCode.activityType,
        season: '',
        beginTime: '',
        endTime: '',
        openType: '',
        productType: '',
        remark: ''
      },
      userInfoRules: {
        phone: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      editInfoRules: {
        season: [{ required: true, message: '请选择', trigger: 'change' }],
        productType: [{ required: true, message: '请选择', trigger: 'change' }],
        openType: [{ required: true, message: '请选择', trigger: 'change' }],
        beginTime: [{ required: true, message: '请选择', trigger: 'change' }],
        endTime: [{ required: true, message: '请选择', trigger: 'change' }],
        remark: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      dict_group: this.$userInfo.dict_group(),
      dict_season: this.$userInfo.getDataList(this, 't_season').then(value => { this.dict_season = value }),
      p_open_type: this.$userInfo.getDataList(this, 'p_open_type').then(value => { this.p_open_type = value }),
      p_word_product_type: this.$userInfo.getDataList(this, 'p_word_product_type').then(value => { this.p_word_product_type = value }),
      addTimepickerOptionsStart: {},
      addTimepickerOptionsEnd: {}
    }
  },
  created () {
    this.getDataList()
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    // 获取列表信息
    getDataList (getType) {
      if (getType === 'search') {
        this.queryForm.pageNum = 1
        this.queryForm.pageSize = 10
      }
      queryOpenProductList(this.queryForm).then((res) => {
        console.log('queryOpenProductList', res)
        this.dataList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('err', err)
      })
    },
    openTypeFormatter (data) {
      var temp = '未设置'
      for (const key in this.p_open_type) {
        if (Object.hasOwnProperty.call(this.p_open_type, key)) {
          const element = this.p_open_type[key]
          if (element.dictValue === data.openType + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    productTypeFormatter (data) {
      var temp = '未设置'
      for (const key in this.p_word_product_type) {
        if (Object.hasOwnProperty.call(this.p_word_product_type, key)) {
          const element = this.p_word_product_type[key]
          if (element.dictValue === data.productType + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    // 修改开始时间的判断
    addBeginTimeChangeStart () {
      if (this.editInfoForm.endTime) {
        if (this.editInfoForm.endTime <= this.editInfoForm.beginTime) {
          this.$message.warning('开始时间必须小于结束时间！')
          this.editInfoForm.beginTime = ''
        }
      }
      this.addTimepickerOptionsEnd = Object.assign({}, this.addTimepickerOptionsEnd, {
        disabledDate: (time) => {
          if (this.editInfoForm.beginTime) {
            return new Date(time).getTime() < new Date(this.editInfoForm.beginTime).getTime()
          }
        }
      })
    },
    // 修改结束时间的判断
    addEndTimeChangeEnd () {
      if (this.editInfoForm.beginTime) {
        if (this.editInfoForm.endTime <= this.editInfoForm.beginTime) {
          this.$message.warning('结束必须大于开始时间！')
          this.editInfoForm.endTime = ''
        }
      }
      this.addTimepickerOptionsStart = Object.assign({}, this.addTimepickerOptionsStart, {
        disabledDate: (time) => {
          if (this.editInfoForm.endTime) {
            return new Date(time).getTime() > new Date(this.editInfoForm.endTime).getTime()
          }
        }
      })
    },
    // 获取用户详情
    getUserInfoDetail () {
      console.log('this.editInfoForm', this.editInfoForm)
      getSysUserInfo({ phone: this.userInfoForm.phone }).then((res) => {
        console.log('getSysUserInfo', res)
        this.userInfoData = res.data
        this.editInfoForm.userId = res.data.id
        this.editInfoForm.phone = this.userInfoForm.phone
        this.editInfoForm.openLevel = res.data.level
        this.editInfoForm.grade = res.data.grade
        this.editInfoForm.schoolId = res.data.schoolId
        this.showEditInfoForm = true
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 保存开通信息点击事件
    editInfo () {
      this.$refs.editInfoFormRef.validate(async valid => {
        if (!valid) return
        this.editInfoForm.type = this.$chnEngStatusCode.OPEN_TYPE_DATE
        this.editInfoForm.productList = []
        addOpenProductList(this.editInfoForm).then((res) => {
          console.log('addOpenProductList', res)
          this.editInfoDialogVisible = false
          this.$message.success('开通成功！')
          this.$refs.editInfoFormRef.resetFields()
          this.reset()
        }).catch((err) => {
          console.log('返回的err', err)
        })
        // 保存之前先判断用户是否注册了小程序
      })
    },
    // 根据产品类型获取产品时间
    productTyChange (value) {
      console.log('productTypeData', value)
      queryOpenProductById({ productType: value, userId: this.editInfoForm.userId }).then((res) => {
        console.log('queryOpenProductById', res)
        if (res.data.isExpired) {
          this.isExpired = res.data.isExpired
        }
        if (res.data.isExpired && res.data.isExpired === 1) {
          this.editInfoForm.beginTime = res.data.endTime
          this.editInfoForm.endTime = ''
        }
      }).catch((err) => {
        console.log('err', err)
      })
    },
    groupFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_group) {
        if (Object.hasOwnProperty.call(this.dict_group, key)) {
          const element = this.dict_group[key]
          if (element.dictValue === data.openLevel + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    // 弹窗关闭
    editInfoDialogClose () {
      this.showEditInfoForm = false
      this.$refs.userInfoFormRef.resetFields()
      this.$refs.editInfoFormRef.resetFields()
    },
    // 重置搜索条件
    reset () {
      this.$refs.queryFormRef.resetFields()
      this.getDataList()
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getDataList()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getDataList()
    }
  }
}
</script>

<style lang="less" scoped>
.div_algin_right {
  text-align: right;
}
</style>
